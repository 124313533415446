
.download-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between ;
}


.download-title {
  height: 30px;
  padding: 5px;
}

.download-desc {
  height: 30px;
  padding: 20px;
}
.download-buttons {
  display: flex;
  flex-direction: column;
}
.download-button {
  padding: 20px;
  margin: 10px;
}
.download-a{
  text-decoration: none !important;
}



#scrollview {
  order: 3;
  position: relative;
  height: 500px;
  padding: 20px;
  margin: 100px 80px;
  background-color: white;
  color: black;
}

.html-content {
  white-space: pre-wrap;
    border: 2px, white;
}